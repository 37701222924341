<template>
  <div></div>
</template>

<script>
export default {
  name: 'new_appointment_old',
  data () {
    return {
      
    }
  },
  methods: {
    redirect() {
      this.$router.push('/terminbuchung')
    }
  },
  mounted () {
    this.redirect();
  }
}
</script>
